console.group("inicializando o pack css do app")

import './recyclable.scss'
import './application.scss'
import './components/base'
import './components/console'
import './components/dash'
import './components/users'
import './components/courses'
import './components/course_templates'
import './components/module_templates'
import './components/activity_templates'
import './components/activity_attachments'
import './components/lesson_templates'
import './components/groups'
import './components/memberships'
import './components/module_rules'
import './components/mods'
import './components/activities'
import './components/lessons'
import './components/movements'
import './components/topics'
import './components/posts'
import './components/reward_templates'



import './overrides'


console.groupEnd();
